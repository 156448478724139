import React from 'react'

import Header from "../components/header"
import FooterShort from "../components/footer_short"

import 'bootstrap/dist/css/bootstrap.min.css'
import "./layout.css"

const layoutHtml = ({children}) => (
	
	<div>
	  <Header />
	  <main id="main">
		
		{children}
	
	  </main>
	  <FooterShort />
	</div>

)

export default layoutHtml;