import React from 'react'
import FooterParts from "../components/footer_parts"

const footerShortHtml = () => (
	
	  <footer>
        <FooterParts />
	  </footer>

)

export default footerShortHtml;
