import React from "react"
import {Link} from "gatsby"

import LayoutShort from "../components/layout_short"
import Seo from "../components/seo"

const errorHtml = ({data,location}) => (
	<LayoutShort>
	  <Seo
		pagetitle="エラー"
		pagepath={location.pathname}
	/>
      <div className="container-fluid thanks-error error404">
  <div className="row">
    <div className="second-top about-mazin bg-img_cover black-filter">
      <div className="contents-title contents-title_white">404<br className="d-md-none" /> ERROR</div>
      <div className="contents-box">
        <h3 className="underline">ご確認ください</h3><div />
        <div className="contents-comment">
          <div className="comment-br">
            お探しのページが見つかりません。<br />
            ページが削除されたか、移動された可能性があります。<br />
            念のため、もう一度アドレスをご確認ください。
          </div>
        </div>{/*.contents-comment*/}
      </div>{/*.contents-box*/}
    </div>{/*.second-top*/}
        <Link to={`/`}><div className="link-top">TOP</div></Link>
  </div></div>

	
	 </LayoutShort>

)

export default errorHtml